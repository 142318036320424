import axios from 'axios';
import { useEffect, useState } from 'react';
import { isLogin } from 'Utils/authUtils';
import { getToken } from 'Utils/Common';

const isDev = process.env.NODE_ENV === 'development';
const X_APP_VERSION = process.env.REACT_APP_VERSION || '0';
// const BASE_URL = isDev ? process.env.REACT_APP_BASE_API || '' : '';
// const BASE_URL = !isDev ? process.env.REACT_APP_BASE_API || '' : '';
const BASE_URL = process.env.REACT_APP_BASE_API;
console.log('isDev', isDev);
console.log('BASE_URL', BASE_URL);
// console.log("process.env.NODE_ENV", process.env.NODE_ENV);
// console.log("process.env.REACT_APP_BASE_API", process.env.REACT_APP_BASE_API);
const CALL_API = axios.create({
  // baseURL: "http://localhost:9000",
  // baseURL: "http://54.179.206.83:801",
  baseURL: BASE_URL,
  timeout: process.env.REACT_APP_API_TIMEOUT || 120000,
});

const tokenExpireResponse = {
  code: 3,
  message: 'Session Timeout, please login again',
};

CALL_API.interceptors.request.use(
  (config) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers['x-app-version'] = X_APP_VERSION;
    if (isLogin()) {
      config.headers['X-Auth-Token'] = getToken();
    }
    return config;
  },
  (error) => Promise.reject(error),
);

/**
 * Login
 */

export const postLogin = async (formData) => {
  try {
    const response = await CALL_API.post(`/api/v1/login`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

/**
 * user
 */

const getUserList = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  return CALL_API.get('/api/v1/users' + queryParam(filters))
    .then((response) => {
      return successResponse(response.data);
    })
    .catch((error) => {
      return notSuccessResponse(error);
    });
};

const patchUser = async (_id, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.patch('/api/v1/users/' + _id, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const patchChangePassword = async (_id, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.patch('/api/v1/users/' + _id, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

/**
 * zone
 */

export const getZoneList = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/zones' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getZoneDetail = async (zoneId) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get(`/api/v1/zones/${zoneId}`);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const postZone = async (formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.post(`/api/v1/zones`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const patchZone = async (formData, zoneId) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.patch(`/api/v1/zones/${zoneId}`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

/**
 * branch
 */

const getBranchList = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/branches' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const getBranchDetail = async (_id) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/branches/' + _id);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const postBranch = async (formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.post('/api/v1/branches', formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const patchBranch = async (_id, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.patch('/api/v1/branches/' + _id, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const patchBranchMachineSeq = async (branchId, formData) => {
  try {
    const response = await CALL_API.patch(`/api/v1/branches/${branchId}/machines`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

/**
 * machine
 */

const getMachineList = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/machines' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const postMachine = async (formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.post('/api/v1/machines', formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const patchMachine = async (_id, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.patch('/api/v1/machines/' + _id, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getMachineHistory = async (machineId) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get(`/api/v1/machines/${machineId}/history`);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

/**
 * line group
 */

const getLineGroupList = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/line-groups' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const putLineGroup = async (_id, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.patch('/api/v1/line-groups', formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const patchLineGroupMember = async (lineGroupId, memberId, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.patch(`/api/v1/line-groups/${lineGroupId}/members/${memberId}`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const patchLineGroupMemberByUser = async (userId, formData) => {
  //debugger;
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.patch(`/api/v1/line-groups/${userId}/roles`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getLineGroupMember = async (_id) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get(`/api/v1/line-groups/${_id}/members`);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

/**
 * Task
 */

export const getServiceTaskList = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/serviceTasks' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getServiceTaskExport = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/serviceTasksExport' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getRefundTaskExport = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/refundTasksExport' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getZoneExport = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/zonesExport' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getMachineExport = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/machineExport' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getJobExport = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/jobsExport' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getBranchExport = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/branchesExport' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getServiceTaskDetail = async (_id) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/serviceTasks/' + _id);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const patchServiceTask = async (taskId, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.patch(`/api/v1/serviceTasks/${taskId}`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const deleteServiceTask = async (taskId, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await axios({
      method: 'DELETE',
      url: `${BASE_URL}/api/v1/serviceTasks/${taskId}`,
      headers: {
        'x-app-version': X_APP_VERSION,
        'X-Auth-Token': getToken(),
      },
      data: {
        ...formData,
      },
    });
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getServiceTaskHistory = async (_id) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get(`/api/v1/serviceTasks/${_id}/history`);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getRefundTaskList = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/refundTasks' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getRefundTaskDetail = async (_id) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/refundTasks/' + _id);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const patchRefundTask = async (_id, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.patch(`/api/v1/refundTasks/${_id}`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

/**
 * Task No-tech
 */

export const getServiceTasksNoTechList = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/serviceTasksNoTech' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getServiceTaskNoTechDetail = async (_id) => {
  try {
    const response = await CALL_API.get('/api/v1/serviceTasksNoTech/' + _id);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const deleteTaskNoTech = async (taskId, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    let _data = null;
    if (formData) {
      _data = {
        ...formData,
      };
    }
    const response = await axios({
      method: 'DELETE',
      url: `${BASE_URL}/api/v1/serviceTasksNoTech/${taskId}`,
      headers: {
        'x-app-version': X_APP_VERSION,
        'X-Auth-Token': getToken(),
      },
      data: _data,
    });
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const deleteTaskService = async (taskId, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    let _data = null;
    if (formData) {
      _data = {
        ...formData,
      };
    }
    const response = await axios({
      method: 'DELETE',
      url: `${BASE_URL}/api/v1/serviceTasksDone/${taskId}`,
      headers: {
        'x-app-version': X_APP_VERSION,
        'X-Auth-Token': getToken(),
      },
      data: _data,
    });
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

/**
 * Planing
 */

export const getServiceTaskPlanningList = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/serviceTaskPlanning' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getServiceTaskTechPlanningList = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/serviceTaskTechPlanning' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

/**
 * Job
 */

export const getJobList = async (filters) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/jobs' + queryParam(filters));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const postJob = async (formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.post(`/api/v1/jobs`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const postUpdateRefundTask = async (formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.post(`/api/v1/refundTasks/${formData._id}/edit`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getServiceTasksLog = async (refundTaskId) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/serviceTasksLog/' + refundTaskId);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getPermissionEdit = async (objPermission) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.post('/api/v1/permissions',objPermission);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getMeRefundTasks = async (refundTaskId) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get('/api/v1/me/refundTasks/' + refundTaskId);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const verificationRefundTasks = async (refundTaskId, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.post(`/api/v1/refundTasks/${refundTaskId}/verification`,formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};


export const postCreateTask = async (formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.post(`/api/v1/serviceTasks`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const PostUploadFile = async (formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.post(`/api/v1/uploads`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const patchJob = async (_id, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.patch(`/api/v1/jobs/${_id}`, formData);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const getJobDetail = async (_id) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await CALL_API.get(`/api/v1/jobs/${_id}`);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const cancelJob = async (jobId, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await axios({
      method: 'DELETE',
      url: `${BASE_URL}/api/v1/jobs/${jobId}/cancel`,
      headers: {
        'x-app-version': X_APP_VERSION,
        'X-Auth-Token': getToken(),
      },
      data: {
        ...formData,
      },
    });
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const closeJob = async (jobId, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await axios({
      method: 'DELETE',
      url: `${BASE_URL}/api/v1/jobs/${jobId}/close`,
      headers: {
        'x-app-version': X_APP_VERSION,
        'X-Auth-Token': getToken(),
      },
      data: {
        ...formData,
      },
    });
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const patchJobTaskService = async (jobId, taskId, formData) => {
  if (!isLogin()) return tokenExpireResponse;
  try {
    const response = await axios({
      method: 'PATCH',
      url: `${BASE_URL}/api/v1/jobs/${jobId}/serviceTasks/${taskId}`,
      headers: {
        'x-app-version': X_APP_VERSION,
        'X-Auth-Token': getToken(),
      },
      data: {
        ...formData,
      },
    });
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

/**
 * Lookup Data
 */

const lookupLineConfig = async () => {
  try {
    const response = await CALL_API.get('/api/v1/lookup/line');
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const lookupDropdown = async () => {
  try {
    const response = await CALL_API.get('/api/v1/lookup/master');
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const lookupMachine = async (branchId, type) => {
  try {
    const response = await CALL_API.get('/api/v1/lookup/machine' + '?branchId=' + branchId?.value + '&type=' + type?.value);
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const lookupLineGroup = async () => {
  try {
    const response = await CALL_API.get('/api/v1/lookup/line-group');
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const lookupUser = async (role) => {
  try {
    const response = await CALL_API.get('/api/v1/lookup/user' + queryParam({ role }));
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const lookupBranch = async () => {
  try {
    const response = await CALL_API.get('/api/v1/lookup/branch');
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

const lookupZone = async () => {
  try {
    const response = await CALL_API.get('/api/v1/lookup/zone');
    return successResponse(response.data);
  } catch (error) {
    return notSuccessResponse(error);
  }
};

export const lookupZoneAndBranch = () => {
  const requestOne = CALL_API.get('/api/v1/lookup/branch');
  const requestTwo = CALL_API.get('/api/v1/lookup/zone');

  return axios
    .all([requestOne, requestTwo])
    .then(
      axios.spread((...responses) => {
        const responseOne = responses[0];
        const responseTwo = responses[1];
        return {
          branch: responseOne.data,
          zone: responseTwo.data,
        };
      }),
    )
    .catch((errors) => {
      return notSuccessResponse(errors);
    });
};

export const lookupBranchAndUser = () => {
  const requestOne = CALL_API.get('/api/v1/lookup/branch');
  const requestThree = CALL_API.get('/api/v1/lookup/user');

  return axios
    .all([requestOne, requestThree])
    .then(
      axios.spread((...responses) => {
        const responseOne = responses[0];
        const responseThree = responses[1];
        return {
          branch: responseOne.data,
          user: responseThree.data,
        };
      }),
    )
    .catch((errors) => {
      return notSuccessResponse(errors);
    });
};

export const lookupZoneAndBranchAndUser = () => {
  const requestOne = CALL_API.get('/api/v1/lookup/branch');
  const requestTwo = CALL_API.get('/api/v1/lookup/zone');
  const requestThree = CALL_API.get('/api/v1/lookup/user');

  return axios
    .all([requestOne, requestTwo, requestThree])
    .then(
      axios.spread((...responses) => {
        const responseOne = responses[0];
        const responseTwo = responses[1];
        const responesThree = responses[2];
        return {
          branch: responseOne.data,
          zone: responseTwo.data,
          user: responesThree.data,
        };
      }),
    )
    .catch((errors) => {
      return notSuccessResponse(errors);
    });
};

/**
 * Utills
 */

const successResponse = (resultData) => {
  const { code } = resultData;
  let message = '';
  if (code === 0) {
    message = 'Successful';
  } else if (code === 2 || code === 3) {
    message = 'Session Timeout, please login again.';
  } else if (code === 4) {
    message = 'Data not found or duplicate value.';
  } else {
    message = 'The request was not successful.';
  }
  return { ...resultData, message };
};

const notSuccessResponse = (error) => {
  if (isDev) console.log(JSON.stringify(error, null, 2));

  const errorResponse = {
    code: error.message ? error.message : '500',
    message: 'Something went wrong.',
  };

  return errorResponse;
};

const queryParam = (query) => {
  delete query.xToken;
  const str = [];
  if (Object.entries(query).length !== 0) {
    for (let [key, value] of Object.entries(query)) {
      if (Array.isArray(value)) value = value.join(',');
      if (value) str.push(`${key}=${value}`);
    }
  }
  let param = '';
  if (str.length > 0) param = '?' + str.join('&');
  return param;
};

export {
  CALL_API,
  getUserList,
  patchUser,
  getBranchList,
  getBranchDetail,
  postBranch,
  patchBranch,
  patchBranchMachineSeq,
  getMachineList,
  postMachine,
  patchMachine,
  getLineGroupList,
  putLineGroup,
  patchLineGroupMember,
  lookupDropdown,
  lookupLineGroup,
  lookupBranch,
  lookupZone,
  lookupLineConfig,
  patchLineGroupMemberByUser,
  lookupMachine,
};

// call api with react hook

export const useGetTaskServiceDetail = () => {
  const [taskServiceDetail, setTaskServiceDetail] = useState(null);
  const [taskId, setTaskId] = useState(null);
  const [isLoadingDetail, setIsLoadingDetail] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingDetail(true);
      const result = await getServiceTaskDetail(taskId);
      // console.log('getServiceTaskDetail[react hook] :: ', result);
      const { code } = result;
      if (code === 0) {
        setTaskServiceDetail(result.data);
      }
      setIsLoadingDetail(false);
    };
    if (taskId) {
      setTaskId(null);
      fetchData();
    }
  }, [taskId]);
  return [{ taskServiceDetail, setTaskServiceDetail, isLoadingDetail, setTaskId }];
};

export const useGetServiceTaskTechPlanningList = () => {
  const [data, setData] = useState(null);
  const [query, setQuery] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const result = await getServiceTaskTechPlanningList(query);
      console.log('serviceTaskTechPlanning [react hook] :: ', result);
      const { code } = result;
      if (code === 0) {
        setData(result.data);
      }
      setIsLoading(false);
    };
    if (query) {
      setQuery(null);
      fetchData();
    }
  }, [query]);
  return [{ data, isLoading, setQuery }];
};
